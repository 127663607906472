import React, { useState, useContext, useEffect } from "react";
import { azureTokenContext, userInfoContext } from "../App";
import Loading from "./Serving/Loading";
import Contracts from "./Serving/Contracts";
import { useNavigate } from "react-router-dom";

import * as ApiCalls from "./Serving/ApiCalls";
import UnresolvedValidationsBox from "./Serving/UnresolvedValidationsBox";

import { Container, Grid, TextField, Button, Avatar, Box } from "@mui/material";

export default function Profile() {
  const azureToken = useContext(azureTokenContext);
  const [userInfo, setUserInfo] = useContext(userInfoContext);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const ckeckData = async () => {
      if (userInfo.id) {
        if (!hasSensitiveInfo()) {
          await addSensitiveInfoToUserInfo();
        }
        setLoading(false);
      }
    };

    ckeckData();
  }, [userInfo.id]);

  const addSensitiveInfoToUserInfo = async () => {
    await ApiCalls.GetUserSensitiveInfo(userInfo, azureToken)
      .then((res) => {
        setUserInfo(() => ({ ...res }));
      })
      .catch((e) => {
        console.log("error on GetUserSensitiveInfo", e);
        log("ERROR", "Failed GetUserSensitiveInfo " + e.message, "Author portal", "", "");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editButtonClicked = () => {
    navigate("/edit-profile");
  };

  const hasSensitiveInfo = () => {
    return userInfo.socialSecurityNumber || userInfo.bankAccountNumber;
  };

  const styleMUITextFields = {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",

    "& .MuiInputBase-root": {
      pointerEvents: "none", // Disable hover effects
    },
  };

  const styleMUIGrid = {
    padding: "6px",
    backgroundColor: "white",
  };

  const log = (level, message, method, workItemId, userRole) => {
    ApiCalls.log(level, message, method, workItemId, userRole, userInfo.id, azureToken);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container>
            <UnresolvedValidationsBox />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Navn"
                      name="name"
                      value={userInfo.name}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="E-post"
                      name="email"
                      value={userInfo.email}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Mobilnummer"
                      name="phone"
                      value={userInfo.phone}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Tittel"
                      name="title"
                      value={userInfo.title}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Arbeidssted"
                      name="employer"
                      value={userInfo.employer}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>

                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Forfatterpresentasjon"
                      name="description"
                      value={userInfo.description}
                      variant="outlined"
                      sx={styleMUITextFields}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Postadresse (privat)"
                      name="address"
                      value={userInfo.address}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Postnummer"
                      name="zipCode"
                      value={userInfo.zipCode}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Sted"
                      name="city"
                      value={userInfo.city}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Land"
                      name="country"
                      value={userInfo.country}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Fødselsnummer"
                      name="socialSecurityNumber"
                      value={userInfo.socialSecurityNumber}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sx={styleMUIGrid}>
                    <TextField
                      fullWidth
                      label="Kontonummer"
                      name="bankAccountNumber"
                      value={userInfo.bankAccountNumber}
                      variant="outlined"
                      sx={styleMUITextFields}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} sx={styleMUIGrid}>
                    <p>Profilbilde:</p>
                    <Box my={2}>
                      <Avatar
                        variant="rounded"
                        src={userInfo.imageUrl ? userInfo.imageUrl : "no-profile-image.png"}
                        alt="Profile"
                        sx={{ width: 150, height: 150 }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <hr></hr> */}
            <div style={{ display: "flex", justifyContent: "center", width: "80%" }}>
              <Contracts />
            </div>
          </Container>
        </>
      )}
    </>
  );
}
