import React from "react";

// import videoFile from "../../assets/video/Revisjon.mp4";

import "../style/home.css";

export default function Home() {
  return (
    <>
      <div style={{ paddingRight: "4rem" }}>
        <h4>Velkommen til Karnovs forfatterportal</h4>
        <br></br>
        <br></br>
        <div>
          <h4>Royalty</h4>
          Utbetaling av royalty opptjent i 2024 utbetales 31. mars 2025. Dersom du skal ha royalty utbetalt som privatperson, ber
          vi deg påse at kontakt- og utbetalingsinformasjon, som ligger under din «Profil» i menyen til venstre, er korrekt
          utfylt. Dersom royalty skal utbetales til firmaet ditt eller arbeidsgiveren din, send en e-post til{" "}
          <a href="mailto:royalty@karnovgroup.no">royalty@karnovgroup.no</a> med navn på firmaet, kontaktpersonen og
          e-postadressen vi skal sende fakturagrunnlaget til. Takk for at du gjør eventuelle endringer innen 1. februar. Du får
          tilsendt ditt opptjente royaltybeløp innen 15. februar.
        </div>{" "}
        <br></br>
        <div>
          <h4>Forfatterfest 2025</h4>
          Det er bare å begynne å glede seg til årets begivenhet: Onsdag 23. april inviterer vi til forfatterfest på Kunstnernes
          Hus.{" "}
          <a href="https://karnov-group-norway.checkoutpage.co/karnovs-forfatterfest-2025" target="_blank" rel="noreferrer">
            Meld deg på allerede nå!
          </a>
        </div>{" "}
        <br></br>
        <div>
          <h4>Revisjoner</h4>
          Visste du at det er svært viktig for kundene våre at kommentarene våre er oppdaterte? For å opprettholde høy kvalitet og
          relevans vil vi derfor sette stor pris på om du ajourfører kommentarene dine årlig. Stadig flere av dere oppdaterer også
          kommentarene med ferske dommer og annet gjennom året. Er du usikker på om du bør revidere? Ta kontakt med din redaktør
          eller send en e-post til: <a href="mailto:redaksjon@karnovgroup.no">redaksjon@karnovgroup.no</a> – vi hjelper deg
          gjerne!
        </div>
        <br></br>
      </div>
    </>
  );
}
