import React, { useState, useContext, useEffect } from "react";
import { azureTokenContext, userInfoContext } from "../../App";
import Loading from "./Loading";

import * as ApiCalls from "./ApiCalls";

import "../../style/contracts.css";

export default function Contracts() {
  const azureToken = useContext(azureTokenContext);
  const [userInfo, setUserInfo] = useContext(userInfoContext);
  const [loadingStatus, setLoadingStatus] = useState("loading");
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    if (userInfo.id) {
      ApiCalls.getContracts(userInfo, azureToken)
        .then((response) => {
          console.log(response);
          setContracts(response);
          setLoadingStatus("done");
        })
        .catch((error) => {
          console.log(error);
          setLoadingStatus("error");
        });
    }
  }, [userInfo.id]);

  return (
    <>
      <>
        {loadingStatus === "done" && contracts && contracts.length > 0 && (
          <>
            <div className="tasks-table-container">
              <table className="tasks-table">
                <thead>
                  <tr>
                    <th className="table-head-title">Dine publikasjoner</th>
                    <th className="table-head-contract-type">Utbetaling til</th>
                  </tr>
                </thead>
                <tbody>
                  {contracts.map((row, index) => (
                    <tr key={index}>
                      <td className="table-body-title">{row.documentTitle}</td>
                      <td className="table-body-contract-type">
                        {row.companyContract && row.companyContract === true
                          ? "Firma: Ditt firma sender faktura"
                          : "Privatperson: Forlaget utbetaler til oppgitt bankkonto"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
      <>{loadingStatus === "error" && <div>** Feil ved innlasting av kontraktene dine</div>}</>
    </>
  );
}
